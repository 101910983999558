<template>
  <form @input="$emit('input', formattedLetterWords)">
    <div v-for="(item, i) in letterWords" :key="i">
      <div v-for="(inner, j) in item" :key="j">
        <input type="text" v-model="inner.list" placeholder="List"/>
        <input type="text" v-model="inner.answer" placeholder="Answer" />
      </div>
    </div>

    <button type="button" @click="addItem">
      + Add Item
    </button>
  </form>
</template>

<script>
// "letterWords": [
//     [
//       {
//         "list": [
//           "Dog",
//           "Adventure",
//           "Clouds"
//         ],
//         "answer": "Dog"
//       },
//       {
//         "list": [
//           "Cat",
//           "Something",
//           "Blue"
//         ],
//         "answer": "Cat"
//       }
//     ]
// ]

export default {
  name: 'Type1b',
  data() {
    return {
      letterWords: [
        [
          {
            list: '',
            answer: '',
          },
        ],
      ],
    };
  },
  computed: {
    formattedLetterWords() {
      let result = JSON.parse(JSON.stringify(this.letterWords));
      result = result.map((item) => {
        return item.map((inner) => {
          inner.list = inner.list
            .replace(/\s/g, '')
            .replace(/[\,\/]/gi, ',')
            .split(',');
          return inner;
        });
      });

      console.log(result);

      return result;
    },
  },
  methods: {
    addItem() {
      this.letterWords.push([
        {
          list: '',
          answer: '',
        },
      ]);
    },
  },
};
</script>
